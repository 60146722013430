// import {useSessionStorage} from '../../../hooks/useSessionStorage';
import { useSessionStorage } from "../../../hooks";
import {
  cancelTransaction,
  checkout,
  getExtendedTransactions,
  reportIncident,
} from "@services/krent.service";
import ktoast from "@services/toast.service";
import { PrimaryButton } from "@shared/buttons";
import { isEmpty } from "@utils/helpers";
import { differenceInDays } from "date-fns";

// import {Modal} from '@shared/controls/Modal';

// import {InputComponent} from '@shared/forms';
import { useState } from "react";
import Modal from "react-responsive-modal";

import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const SlidePanel = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div className={`slide-panel ${isOpen ? "open" : ""}`}>
      <button onClick={onClose} className="slide-panel-close">
        ✕
      </button>
      <div className="slide-panel-content">{children}</div>
    </div>
  );
};

const TransactionTableData = ({ data, getTransactions }: any) => {
  // const [user] = useSessionStorage('user', {});
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showExtensionModal, setShowExtentionModal] = useState<boolean>(false);
  const [extensionLoading, setExtentionLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>("");
  const [openIncident, setOpenIncident] = useState(false);
  const [initialDate, setInitialDates] = useState({
    arrivalDate: "",
    departureDate: "",
    refId: "",
    transactionStatus: "",
  });

  const [incident, setIncident] = useState("");
  const [extendedTransactions, setExtendedTransactions] = useState<any>([]);
  const [earlyCheckoutDisclaimer, setEarlyCheckoutDisclaimer] =
    useState<boolean>(false);
  const [slidePanelOpen, setSlidePanelOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<any>(null);

  const [user] = useSessionStorage("user", {});

  const handleManageBooking = (transaction: any) => {
    setSelectedTransaction(transaction);
    setSlidePanelOpen(!slidePanelOpen);
  };

  const handleCancelTransaction = (reason: string) => {
    setLoading(true);

    cancelTransaction(transactionId, reason)
      .then(() => {
        // navigate('/explore-listings');
        setCancellationReason("");
        setLoading(false);
        ktoast.success("Transactions successfully cancelled");
      })
      .catch((err) => {
        console.log(err);
        ktoast.error(err?.errors[0]?.message);

        setLoading(false);
      })
      .finally(() => {
        getTransactions();
      });
  };

  const handleSubmitIncident = (e: any) => {
    e.preventDefault();
    setLoading(true);
    reportIncident(transactionId, incident)
      .then((resp: any) => {
        ktoast.success(resp?.message);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCheckout = (id: string) => {
    let abortController = new AbortController();

    checkout(id, abortController.signal)
      .then((res: any) => {
        // console.log(res);
        // setTransaction(res.data);

        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExtendedTransactions = (id: string) => {
    const abortController = new AbortController();
    setExtentionLoading(true);
    getExtendedTransactions(id, user.id, abortController.signal)
      .then((d: any) => {
        setExtendedTransactions(d.data);
      })
      .catch(() => {
        // Failed to load transactions
      })
      .finally(() => {
        setLoading(false);
        setExtentionLoading(false);
      });
  };

  return (
    <>
      <tbody className="list fs-base">
        <ToastContainer limit={1} />

        {data.map((info: any) => {
          const {
            arrival_date,
            createdAt,
            updatedAt,
            departure_date,
            isExtended,
            // inspection_date,

            id,
            owner,
            originalTransaction,
            // type,
            // property_avalability_status,
            status,
            listing,
            hasCheckedOut,
            referenceId,
          } = info;
          return (
            <tr key={id}>
              <td>
                <Link
                  to={`/property/${listing?.slug}/`}
                  state={{ id: listing?.slug }}
                >
                  {listing?.title.length > 0 &&
                    listing?.title.slice(0, 20) + "..."}
                </Link>
              </td>
              <td>
                <span className="item-intent">{owner?.preferredName}</span>
              </td>
              {/* <td>
              <span className='item-intent'>{listing?.intent}</span>
            </td> */}
              <td>
                <span className="item-bedrooms">
                  {/* {moment(arrival_date).format('MMM Do YY')} */}

                  {listing?.intent === "sale"
                    ? "N/A"
                    : new Date(
                        arrival_date?.toLocaleString()
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                </span>
              </td>
              <td>
                <span className="item-location">
                  {listing?.intent === "sale"
                    ? "N/A"
                    : new Date(
                        departure_date?.toLocaleString()
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                </span>
              </td>

              <td>
                <span className="item-price">
                  {isExtended ? (
                    <span className="badge bg-success"> Yes</span>
                  ) : (
                    <span className="badge bg-danger"> No </span>
                  )}
                </span>
              </td>
              <td>
                <span className="item-price">
                  {new Date(updatedAt?.toLocaleString()).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </td>
              <td>
                <span className="item-createdAt text-reset">
                  {new Date(createdAt?.toLocaleString()).toLocaleDateString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    }
                  )}
                </span>
              </td>
              {/* <span className='item-createdAt text-reset'>{status}</span> */}
              <td className="">
                {status === "customer-accepted" ? (
                  <div className="badge bg-primary">Accepted</div>
                ) : status === "completed" ? (
                  <div className="badge bg-success">Completed</div>
                ) : status === "progress" ? (
                  <div className="badge bg-warning">In Progress</div>
                ) : (
                  <div className="badge bg-danger">{status}</div>
                )}
              </td>
              {listing?.intent === "long lease" ? (
                <>
                  <td className="">
                    {/* <Link to={`/message/${id}`} className=" fs-13">
                Manage Booking
              </Link> */}
                    <div
                      className="text-primary fw-bold fs-12 cursor-pointer"
                      onClick={() => handleManageBooking(info)}
                    >
                      Manage Booking
                    </div>
                  </td>
                  <td className="">
                    <button
                      className="dropdown-item btn fw-bold btn-link fs-13 border-input border-round-xl p-1 text-center text-red"
                      onClick={() => {
                        setShowCancelModal(true);
                        setTransactionId(id);
                      }}
                    >
                      X &nbsp;Close Transaction
                    </button>
                  </td>
                </>
              ) : (
                <td className="">
                  <div className="dropdown">
                    <button
                      className=" dropdown-ellipsis btn btn-link"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      type="button"
                      onClick={() => {
                        if (isExtended) {
                          handleExtendedTransactions(id);
                          setTransactionId(id);
                          setInitialDates({
                            ...initialDate,
                            arrivalDate: arrival_date,
                            departureDate: departure_date,
                            refId: referenceId,
                            transactionStatus: status,
                          });
                        }
                      }}
                    >
                      <i className="fa-solid fa-ellipsis-vertical text-primary"></i>
                    </button>

                    <div
                      className="dropdown-menu dropdown-menu-end transform-none"
                      style={{ transform: "none !important" }}
                    >
                      {originalTransaction ? (
                        <Link
                          to={`/message/${originalTransaction}`}
                          className="dropdown-item fs-13"
                        >
                          Manage Booking
                        </Link>
                      ) : (
                        <Link
                          to={`/message/${id}`}
                          className=" fs-13 dropdown-item"
                        >
                          Manage Booking
                        </Link>
                      )}
                      {listing?.intent === "shortlet" &&
                        status !== "cancelled" && (
                          <>
                            {status === "completed" && hasCheckedOut ? (
                              <p className="fs-14 text-primary m-0 dropdown-item">
                                You have successful Checked out
                              </p>
                            ) : (
                              <div>
                                {(() => {
                                  const currentTime = new Date().getTime();
                                  const arrivalTime = new Date(
                                    arrival_date
                                  ).getTime();
                                  const departureTime = new Date(
                                    departure_date
                                  ).getTime();

                                  const hasValidFutureExtension =
                                    isExtended &&
                                    extendedTransactions?.some(
                                      (trans: any) =>
                                        new Date(trans.arrival_date).getTime() >
                                        currentTime
                                    );

                                  if (hasValidFutureExtension) {
                                    return null;
                                  }

                                  if (
                                    currentTime >= arrivalTime &&
                                    currentTime < departureTime
                                  ) {
                                    return (
                                      <span
                                        className="fs-13 dropdown-item cursor-pointer"
                                        onClick={() => {
                                          setEarlyCheckoutDisclaimer(true);
                                          setTransactionId(id);
                                        }}
                                      >
                                        Early Checkout from shortlet
                                      </span>
                                    );
                                  } else if (
                                    currentTime === departureTime ||
                                    departureTime < currentTime
                                  ) {
                                    return (
                                      <span
                                        className="fs-13 dropdown-item cursor-pointer"
                                        onClick={() =>
                                          handleCheckout(
                                            originalTransaction || id
                                          )
                                        }
                                      >
                                        Checkout from shortlet
                                      </span>
                                    );
                                  }

                                  return null;
                                })()}
                              </div>
                            )}
                            {isExtended && (
                              <div>
                                <button
                                  className="dropdown-item fs-13"
                                  type="button"
                                  onClick={() => {
                                    setShowExtentionModal(true);
                                  }}
                                >
                                  View Extensions
                                </button>
                                <ul className="dropdown-menu">
                                  {extendedTransactions?.map((trans: any) => (
                                    <li key={trans.id}>
                                      <p className="fs-14 cursor-pointer dropdown-item">
                                        {new Date(
                                          trans?.arrival_date?.toLocaleString()
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })}
                                        {" - "}
                                        {new Date(
                                          trans?.departure_date?.toLocaleString()
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })}
                                        {" ("}
                                        {differenceInDays(
                                          new Date(trans?.departure_date),
                                          new Date(trans?.arrival_date)
                                        )}
                                        {trans.listing.intent === "shortlet"
                                          ? " night)"
                                          : " days)"}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        )}

                      {status === "progress" && (
                        <button
                          className="dropdown-item btn btn-link fs-13 text-danger"
                          onClick={() => {
                            setShowCancelModal(true);
                            setTransactionId(id);
                          }}
                        >
                          Cancel Transaction
                        </button>
                      )}
                      {listing.intent === "long lease" &&
                        status === "completed" && (
                          <button
                            className="dropdown-item btn btn-link fs-13"
                            onClick={() => {
                              setOpenIncident(true);
                              setTransactionId(id);
                            }}
                          >
                            Report a problem
                          </button>
                        )}
                    </div>
                  </div>
                </td>
              )}
            </tr>
          );
        })}

        <Modal
          center
          open={showCancelModal}
          onClose={() => {
            setShowCancelModal(false);
            getTransactions();
          }}
          classNames={{
            // overlayAnimationIn: 'customEnterOverlayAnimation',
            // overlayAnimationOut: 'customLeaveOverlayAnimation',
            modalAnimationIn: "customEnterModalAnimation",
            modalAnimationOut: "customLeaveModalAnimation",
            modal: "customModal",
          }}
          animationDuration={500}
          aria-labelledby="summary-title"
          aria-describedby="summary-description"
        >
          <div className="col-12 mt-5 ">
            <h6 className="fs-12 fw-bold h-modal-color">Cancel Transaction</h6>
            <p className="fs-14 light-text" id="summary-description">
              We are sorry you have to cancel this transaction. In other to
              serve you better please share with us the reason why you are
              cancelling this transaction.
            </p>
            <form
              className="mt-4 mt-md-3"
              onSubmit={(e) => {
                e.preventDefault();
                handleCancelTransaction(cancellationReason);
              }}
            >
              <div className="col mb-4">
                <textarea
                  className="form-control border-round-xl py-3 px-4 fs-14 bg-purple"
                  required
                  placeholder="Please state your reason"
                  rows={4}
                  maxLength={500}
                  value={cancellationReason}
                  onChange={(e: any) => setCancellationReason(e.target.value)}
                ></textarea>
              </div>

              <div className="col text-center">
                <PrimaryButton type="primary" text="Submit" loading={loading} />
              </div>
            </form>
          </div>
        </Modal>
        {/* Extesion modal */}
        <Modal
          center
          open={showExtensionModal}
          onClose={() => {
            setShowExtentionModal(false);
          }}
          classNames={{
            // overlayAnimationIn: 'customEnterOverlayAnimation',
            // overlayAnimationOut: 'customLeaveOverlayAnimation',
            modalAnimationIn: "customEnterModalAnimation",
            modalAnimationOut: "customLeaveModalAnimation",
            modal: "customModal",
          }}
          animationDuration={500}
          aria-labelledby="summary-title"
          aria-describedby="summary-description"
        >
          <div className="col-12 mt-5 ">
            <h6 className="fs-16 fw-bold h-modal-color mb-2">
              Booking History
            </h6>
            <div>
              <div>
                <h6 className="fs-13 fw-bold h-modal-color">Initial Booking</h6>
                <p className="fs-14 cursor-pointer ">
                  {new Date(
                    initialDate.arrivalDate?.toLocaleString()
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                  {" - "}
                  {new Date(
                    initialDate.departureDate?.toLocaleString()
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                  {" ("}
                  {differenceInDays(
                    new Date(initialDate.departureDate),
                    new Date(initialDate.arrivalDate)
                  )}
                  night {")"}
                </p>
                <span className="fs-10 mr-1">
                  Ref ID : {initialDate?.refId}{" "}
                </span>{" "}
                <>
                  <span className="fs-10">Status : </span>
                  <span
                    className={`fs-10 ${
                      initialDate.transactionStatus === "completed"
                        ? "text-success"
                        : "text-danger"
                    } `}
                  >
                    {initialDate.transactionStatus}{" "}
                  </span>
                </>
              </div>

              <h6 className="fs-13 fw-bold h-modal-color mt-3">Extensions</h6>

              {extensionLoading ? (
                <span>Loading...</span>
              ) : (
                <ul className="">
                  {extendedTransactions?.map((trans: any) => {
                    return (
                      <li key={trans.id} className="mb-2">
                        <p className="fs-14 cursor-pointer ">
                          {new Date(
                            trans?.arrival_date?.toLocaleString()
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                          {" - "}
                          {new Date(
                            trans?.departure_date?.toLocaleString()
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                          {" ("}
                          {differenceInDays(
                            new Date(trans?.departure_date),
                            new Date(trans?.arrival_date)
                          )}
                          {trans.listing.intent === "shortlet"
                            ? " night)"
                            : " days)"}
                        </p>
                        <span className="fs-10 mr-1">
                          Ref ID : {trans.referenceId}{" "}
                        </span>{" "}
                        <>
                          <span className="fs-10">Status : </span>
                          <span
                            className={`fs-10 ${
                              trans.status === "completed"
                                ? "text-success"
                                : "text-danger"
                            } `}
                          >
                            {trans.status}{" "}
                          </span>
                        </>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </Modal>
        <Modal
          open={openIncident}
          center
          onClose={() => setOpenIncident(false)}
          // classNames={{ modal: 'w-300 rounded' }}
          classNames={{
            // overlayAnimationIn: 'customEnterOverlayAnimation',
            // overlayAnimationOut: 'customLeaveOverlayAnimation',
            modalAnimationIn: "customEnterModalAnimation",
            modalAnimationOut: "customLeaveModalAnimation",
            modal: "customModal",
          }}
          animationDuration={800}
        >
          <div className="col-10 mt-3">
            <div className="col-12">
              <h6 className="fs-12 fw-bold h-modal-color">
                Report an Incident
              </h6>
              <p className="fs-14 light-text" id="summary-description">
                If you have any complaint or encounter any challenges with the
                Agent or Landlord please report it we will be happy to help
                resolve it.
              </p>
              <form className="mt-4 mt-md-3" onSubmit={handleSubmitIncident}>
                <div className="col mb-4">
                  <textarea
                    name="incident"
                    id="incident"
                    placeholder="Please state your complaint"
                    value={incident}
                    onChange={(e: any) => setIncident(e.target.value)}
                    required
                    className="w-100 p-3"
                    rows={5}
                  ></textarea>
                </div>

                <div className="col text-center">
                  <PrimaryButton
                    type="primary"
                    text="Submit"
                    loading={loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <Modal
          open={earlyCheckoutDisclaimer}
          center
          onClose={() => setEarlyCheckoutDisclaimer(false)}
          // classNames={{ modal: 'w-300 rounded' }}
          classNames={{
            // overlayAnimationIn: 'customEnterOverlayAnimation',
            // overlayAnimationOut: 'customLeaveOverlayAnimation',
            modalAnimationIn: "customEnterModalAnimation",
            modalAnimationOut: "customLeaveModalAnimation",
            modal: "customModal",
          }}
          animationDuration={800}
        >
          <div className="col-10 mt-3">
            <div className="col-12">
              <h6 className="fs-12 fw-bold h-modal-color">Disclaimer</h6>
              <p className="fs-14 light-text" id="summary-description">
                Early checkout may result in no refund, except in cases of
                property faults or unforeseen events which depends on host
                cancellation policy, circumstance, and Krent evaluation. Contact
                us within 24 hours to resolve any disputes.
              </p>

              <div className="col text-center">
                <button
                  onClick={() => handleCheckout(transactionId)}
                  className="btn btn-primary w-100 mt-2"
                >
                  {" "}
                  Checkout from shortlet
                </button>
                <button
                  onClick={() => setEarlyCheckoutDisclaimer(false)}
                  className="btn btn-outline-search  w-100 mt-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </tbody>
      <SlidePanel
        isOpen={slidePanelOpen}
        onClose={() => setSlidePanelOpen(false)}
      >
        {selectedTransaction && (
          <div>
            <Link
              to={`/message/${selectedTransaction.id}`}
              className="dropdown-item fs-13 bg-primary rounded-50 w-max text-white fw-bold py-2 px-4"
              style={{ marginTop: "40px" }}
            >
              Go to messaging
            </Link>

            <div className="text-center mt-4">
              <img
                src={
                  "https://aui.atlassian.com/aui/8.8/docs/images/avatar-person.svg"
                }
                alt=""
                width={100}
                className="rounded-circle"
              />
              <p className="uppercase fw-bold my-2">
                {selectedTransaction.owner?.preferredName}
              </p>

              <h6 className="fw-bold my-2">
                {selectedTransaction?.sent_by?.name}
              </h6>
            </div>
            <div className="text-start mt-5 mb-4">
              <h6 className="fs-12 fw-bold m-0">REQUEST TYPE</h6>
              <p className="fs-14 m-0">
                {selectedTransaction?.listing?.intent}
              </p>
            </div>
            {selectedTransaction?.listing?.intent !== "sale" &&
              selectedTransaction &&
              Object.keys(selectedTransaction).length !== 0 && (
                <div className="text-start mb-4">
                  <h6 className="fs-12 fw-bold m-0">DURATION</h6>
                  <p className="fs-14 m-0">
                    {new Date(
                      selectedTransaction?.arrival_date?.toLocaleString()
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                    {" - "}
                    {new Date(
                      selectedTransaction?.departure_date?.toLocaleString()
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                    {" ("}
                    {differenceInDays(
                      new Date(selectedTransaction?.departure_date),
                      new Date(selectedTransaction?.arrival_date)
                    )}{" "}
                    {selectedTransaction.listing.intent === "shortlet"
                      ? " night)"
                      : " days)"}
                  </p>
                </div>
              )}
            <div className="text-start  mb-4">
              <h6 className="fs-12 fw-bold m-0">Transaction Reference</h6>
              <p className="fs-14 m-0">{selectedTransaction?.referenceId}</p>
            </div>

            {/* Booking Details */}
            <div className="space-y-4">
              {selectedTransaction &&
                selectedTransaction?.status &&
                (selectedTransaction?.status === "progress" ||
                  selectedTransaction?.status === "awaiting-inspection") &&
                selectedTransaction?.inspection_date &&
                !isEmpty(selectedTransaction?.inspection_schedules) && (
                  <div className="alert alert-primary" role="alert">
                    <p className="fs-14">
                      Inspection Date has been scheduled for
                      {new Date(
                        selectedTransaction?.inspection_date?.toLocaleString()
                      ).toLocaleTimeString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </p>
                  </div>
                )}
            </div>
          </div>
        )}
      </SlidePanel>
    </>
  );
};

export default TransactionTableData;
