import { useState, useEffect } from "react";
import valimg from "../assets/images/val.png";

const ValBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();

    const startDate = new Date(year, 1, 12);
    const endDate = new Date(year, 1, 20);

    setShowBanner(today >= startDate && today <= endDate);
  }, []);

  if (!showBanner) return null;

  return (
    <div
      className="flex align-items-center justify-content-center mx-auto py-1"
      style={{ backgroundColor: "#eb6666" }}
    >
      <div className="relative flex items-center gap-3">
        <img src={valimg} alt="val" style={{ width: "70px" }} />

        <h4 className="text-white xmas-text position-relative ">
          It's the season of Love: Spread the Joy!
        </h4>

        <img src={valimg} alt="val" style={{ width: "70px" }} />
      </div>
    </div>
  );
};

export default ValBanner;
