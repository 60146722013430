import React, { useEffect, useState } from "react";
import Select from "react-select";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; //
// import { ImageUploadCard } from '@shared/cards';

// import map from '../../../assets/images/map.png';
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import {
  getPropertyKinds,
  // getPropertySpaces,
  getCategories,
  addProperImages,
  // deleteProperty,
  updateProperty,
  getPropertyAmenities,
  // getSingleProperty,
  getSingleListingProperty,
} from "@services/krent.service";
import { PropertyKind } from "@interfaces/properties.interface";
import { BaseApiResponse, PaginatedResponse } from "@interfaces/app.interface";
import ktoast from "@services/toast.service";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton } from "@shared/buttons";
import { Modal } from "@shared/controls/Modal";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  StandaloneSearchBox,

  // DirectionsRenderer,
} from "@react-google-maps/api";
import updateArray from "@utils/updateArray";
import PropertyVideo from "@shared/Video";
import { Amenities } from "@interfaces/requests.interface";
import { formatNumberWithCommas } from "@utils/helpers";

// import { amenitiesData } from '../data';

export function Step1({
  onNext,
  propertyName,
  setPropertyName,
  propertyDesc,
  setPropertyDesc,
}: any) {
  // const [error] = useState<string>('Title must be minimum of 10 characters');

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form className="needs-validation" noValidate onSubmit={handleOnSubmit}>
      <div className="form-group mb-4 ">
        <label className="fw-bold mb-2">
          What’s the name of your property?
        </label>
        <input
          type="text"
          className="form-control rounded-pill fs-14 py-3 px-4 bg-purple"
          placeholder="Enter property name"
          defaultValue={propertyName}
          onChange={(e) => {
            setPropertyName(e.target.value);
          }}
          required
        />
        {/* {propertyName && propertyName?.length! < 10 ? (
          <span className='text-danger mt-1 ml-3 d-block'>{error}</span>
        ) : (
          ''
        )} */}
      </div>

      {/* Tippy is added is it can work across all browser and also all device */}
      <div className="form-group">
        <label className="fw-bold mb-2">Describe your property?</label>
        <Tippy content="Please do not include the property address.">
          <textarea
            className="form-control border-round-xl py-3 px-4 fs-14 bg-purple"
            required
            placeholder="Enter a short description of this property"
            rows={6}
            maxLength={500}
            value={propertyDesc}
            onChange={(e) => setPropertyDesc(e.target.value)}
          ></textarea>
        </Tippy>
      </div>
      <div className="col-12 text-end m-2">
        <button
          className="btn btn-outline btn-dark py-3 px-5"
          type="submit"
          // disabled={propertyName.length < 10}
        >
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step2({
  propertyType,
  setPropertyType,
  propertyCategory,
  setPropertyCategory,
  affiliation,
  setAffiliation,
  // intent,
  // setIntent,
  onNext,
  onPrev,
}: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [kinds, setKinds] = useState<PropertyKind[]>([]);
  const [categories, setCategories] = useState<any>([]);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);

    getPropertyKinds(abortController.signal)
      .then((resp: BaseApiResponse<PropertyKind[], PaginatedResponse>) => {
        setKinds(resp.data);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      })
      .finally(() => {
        setLoading(false);
      });

    getCategories(abortController.signal)
      .then((res: any) => {
        setCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const propertyAffiliation = [
    { title: "Property owner", id: 1 },
    { title: "Direct agent", id: 2 },
    { title: "Indirect agent", id: 3 },
  ];

  const fullyServiced = categories.find(
    (cat: { title: string; description: string; id: string }) =>
      cat.title === "Fully Serviced"
  );

  return (
    <form className="needs-validation" onSubmit={handleOnSubmit} noValidate>
      <div className="form-group mb-4">
        <h4 className="fs-18 fw-bold mb-3">Property Type</h4>
        <label className="fw-bold mb-2">What type of property is this?</label>
        <select
          required
          className="form-select rounded-pill py-3 px-4 bg-purple"
          value={propertyType?._id}
          onChange={(e) => {
            setPropertyType(e.target.value);
          }}
        >
          <option value={propertyType._id}>{propertyType?.title}</option>
          {/* <option value={propertyType._id}>Select An Option</option> */}
          {kinds.map((d) => {
            return (
              <option key={d.id} value={d.id}>
                {d.title}
              </option>
            );
          })}
        </select>
      </div>

      <div className="form-group mb-4">
        <label className="fw-bold mb-2">What is the property category?</label>
        <select
          required
          className="form-select rounded-pill py-3 px-4 bg-purple"
          defaultValue={propertyCategory}
          onChange={(e) => {
            setPropertyCategory(e.target.value);
          }}
        >
          {/* <option value=''>Select An Option</option> */}
          {/* <option value={categories[2]?.id}>{categories[2]?.title}</option> */}
          <option value={fullyServiced && fullyServiced.id}>
            {fullyServiced && fullyServiced?.title}
          </option>
          {/* {categories.map((d: any) => {
            console.log(categories);
            return (
              <option key={d.id} value={d.id}>
                {d.title}
              </option>
            );
          })} */}
        </select>
      </div>
      {/* <div className='form-group mb-4'>
        <label className='fw-bold mb-2'>Property Intent?</label>
        <select
          required
          className='form-select rounded-pill py-3 px-4 bg-purple'
          defaultValue={intent}
          onChange={(e) => {
            setIntent(e.target.value);
          }}
        >  
          <option value='shortlet'>Shortlet</option>
        </select>
      </div> */}
      <div className="form-group mb-4">
        <label className="fw-bold mb-2">
          What is your affiliation with this property?
        </label>
        <select
          required
          className="form-select rounded-pill py-3 px-4 bg-purple"
          defaultValue={affiliation}
          onChange={(e) => {
            setAffiliation(e.target.value);
          }}
        >
          <option value="">Select An Option</option>
          {propertyAffiliation.map((d: any) => {
            return (
              <option key={d.id} value={d.title}>
                {d.title}
              </option>
            );
          })}
        </select>
      </div>

      {/* {propertyType && propertyType !== 'Select An Option' && (
				<div className='border border-round bg-purple p-3'>
					{propertyType === 'apartment' && (
						<p className='fs-14 m-0'>
							An apartment is a suite of rooms forming one residence, typically
							in a building containing a number of these.
						</p>
					)}
				</div>
			)} */}

      <div className="col-12 text-end m-2">
        <button type="button" className="btn-el fw-bold mr-3" onClick={onPrev}>
          Go Back
        </button>
        <button
          disabled={loading}
          className="btn btn-outline btn-dark py-3 px-5"
          type="submit"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

// export function Step3({ spaceType, setSpaceType, onNext, onPrev }: any) {
//   const [loading, setLoading] = useState<boolean>(false);
//   const [spaces, setSpaces] = useState<PropertySpace[]>([]);

//   const handleOnSubmit = (e: any) => {
//     e.preventDefault();
//     onNext();
//   };

//   useEffect(() => {
//     const abortController = new AbortController();
//     setLoading(true);
//     getPropertySpaces(abortController.signal)
//       .then((resp: BaseApiResponse<PropertySpace[], PaginatedResponse>) => {
//         setSpaces(resp.data);
//       })
//       .catch((err) => {
//         ktoast.error(err.errors[0].message);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   }, []);

//   return (
//     <form className='needs-validation' onSubmit={handleOnSubmit} noValidate>
//       <div className='form-group mb-4'>
//         <h4 className='fs-18 fw-bold mb-3'>Space Details</h4>
//         <label className='fw-bold mb-2'>
//           What type of space will you provide to your customers?
//         </label>

//         <div className='row'>
//           {spaces.map((d) => (
//             <div key={d.id} className='col-6'>
//               <div className='form-check mb-2'>
//                 <input
//                   className='form-check-input'
//                   type='checkbox'
//                   value={d.id}
//                   name='space[]'
//                   onChange={(e) => setSpaceType(e.target.value)}
//                   checked={spaceType === d.id}
//                 />
//                 <label className='form-check-label light-text'>
//                   {d.title === 'An entire space'
//                     ? 'An entire apartment'
//                     : d.title}
//                 </label>
//               </div>
//             </div>
//           ))}
//           {/* <div>
// 						{spaceType && spaceType !== '' && (
// 							<div className='border border-round bg-purple p-3'>

// 									<p className='fs-14 m-0'>
// 										An entire space is a suite of rooms forming one residence,
// 										typically in a building containing a number of these.
// 									</p>
// 							</div>
// 						)}
// 					</div> */}
//         </div>
//       </div>

//       <div className='col-12 text-end m-2'>
//         <button className='btn-el fw-bold mr-3' onClick={onPrev}>
//           Go Back
//         </button>
//         <button
//           disabled={loading}
//           className='btn btn-outline btn-dark py-3 px-5'
//           type='submit'
//         >
//           Continue
//         </button>
//       </div>
//     </form>
//   );
// }

export function Step4({
  guests,
  setGuests,
  bedrooms,
  setBedrooms,
  // beds,
  // setBeds,
  toilets,
  setToilets,
  rules,
  setRules,

  bathrooms,
  setBathrooms,
  checkInRules,
  setCheckInRules,
  checkOutRules,
  setCheckOutRules,
  cancellationPolicy,
  setCancellationPolicy,
  // floors,
  // setFloors,
  // garages,
  // setGarages,
  // size,
  // setSize,
  onNext,
  onPrev,
}: any) {
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };
  return (
    <form className="needs-validation" onSubmit={handleOnSubmit} noValidate>
      <h4 className="fs-18 fw-bold mb-3 mb-4">Additional Space Information</h4>
      <div className="row">
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <div className="form-group mb-4">
            <label className="fw-bold mb-2">Maximum No Of Guests</label>
            <input
              type="number"
              className="form-control rounded-pill  px-4 py-3 bg-purple"
              placeholder="Enter a number"
              defaultValue={guests}
              min={1}
              onChange={(e) => setGuests(e.target.value)}
              required
            ></input>
          </div>
          {/* <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>No Of Beds</label>
            <input
              type='number'
              className='form-control rounded-pill px-4 py-3 bg-purple'
              placeholder='Enter a number'
              defaultValue={beds}
              onChange={(e) => setBeds(e.target.value)}
              // required
            ></input>
          </div> */}
          {/* <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>No Of Floors</label>
            <input
              type='number'
              className='form-control rounded-pill  px-4 py-3 bg-purple'
              placeholder='Enter a number'
              defaultValue={floors}
              onChange={(e) => setFloors(e.target.value)}
              // required
            ></input>
          </div> */}
          {/* <div className='form-group'>
            <label className='fw-bold mb-2'>Size</label>
            <div className='input-group'>
              <input
                type='number'
                min={0}
                className='form-control rounded-pill  px-4 py-3 bg-purple w-60'
                placeholder='Enter a number'
                defaultValue={size}
                onChange={(e) => setSize(e.target.size)}
                required
              />
              <select className='form-select rounded-pill px-4 py-3 border-left-0 bg-purple w-30'>
                <option>sqm</option>
              </select>
            </div>
          </div> */}
          <div className="form-group mb-4">
            <label className="fw-bold mb-2">No Of Bedrooms</label>
            <input
              type="number"
              className="form-control rounded-pill px-4 py-3 bg-purple"
              placeholder="Enter a number"
              defaultValue={bedrooms}
              min={1}
              onChange={(e) => setBedrooms(e.target.value)}
              required
            ></input>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group mb-4">
            <label className="fw-bold mb-2">No Of Bathrooms</label>
            <input
              type="number"
              className="form-control rounded-pill px-4 py-3 bg-purple"
              placeholder="Enter a number"
              defaultValue={bathrooms}
              min={1}
              onChange={(e) => setBathrooms(e.target.value)}
              required
            ></input>
          </div>
          <div className="form-group mb-4">
            <label className="fw-bold mb-2">No Of Toilets</label>
            <input
              type="number"
              className="form-control rounded-pill px-4 py-3 bg-purple"
              placeholder="Enter a number"
              defaultValue={toilets}
              min={1}
              onChange={(e) => setToilets(e.target.value)}
              required
            ></input>
          </div>
          {/* <div className='form-group mb-4'>
            <label className='fw-bold mb-2'>No Of Garages</label>
            <input
              type='number'
              className='form-control rounded-pill px-4 py-3 bg-purple'
              placeholder='Enter a number'
              defaultValue={garages}
              onChange={(e) => setGarages(e.target.value)}
              // required
            ></input>
          </div> */}
        </div>

        <div className="form-group mb-4">
          <label className="fw-bold mb-2">House Rules</label>

          <textarea
            id="form_message"
            className="form-control  px-4 py-3 bg-purple"
            name="message"
            placeholder="Type your house rules here."
            rows={4}
            defaultValue={rules}
            required
            onChange={(e) => setRules(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group mb-4">
          <label className="fw-bold mb-2">Cancellation Policy</label>

          <textarea
            id="form_message"
            className="form-control  px-4 py-3 bg-purple"
            name="cancellation"
            placeholder="Type your cancellation policy here."
            rows={6}
            defaultValue={cancellationPolicy}
            // required
            onChange={(e) => setCancellationPolicy(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group mb-4">
          <label className="fw-bold mb-2">CheckIn Policy</label>

          <textarea
            id="form_message"
            className="form-control  px-4 py-3 bg-purple"
            name="check in"
            placeholder="Type your check in rule/policy here."
            rows={6}
            defaultValue={checkInRules}
            // required
            onChange={(e) => setCheckInRules(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group mb-4">
          <label className="fw-bold mb-2">Checkout Policy</label>

          <textarea
            id="form_message"
            className="form-control  px-4 py-3 bg-purple"
            name="check in"
            placeholder="Type your check out rule/policy here."
            rows={6}
            defaultValue={checkOutRules}
            // required
            onChange={(e) => setCheckOutRules(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div className="col-12 text-end m-2 ">
        <button className="btn-el fw-bold mr-3" onClick={onPrev}>
          Go Back
        </button>
        <button className="btn btn-outline btn-dark py-3 px-5" type="submit">
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step5({
  price,
  setPrice,
  cautionFee,
  setCautionFee,
  onNext,
  onPrev,
}: any) {
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    onNext();
  };

  return (
    <form className="needs-validation" onSubmit={handleOnSubmit} noValidate>
      <h4 className="fs-18 fw-bold mb-3 mb-4">Pricing Information</h4>

      <div className="form-group mb-4">
        <label className="fw-bold mb-2">Cost Per Night</label>
        <div className="input-group d-flex flex-row gap-2">
          <input
            type="text"
            defaultValue={price}
            value={formatNumberWithCommas(price)}
            min={1}
            onChange={(e) => setPrice(e.target.value.replace(/,/g, ""))}
            className="form-control rounded-pill px-4 py-3 bg-purple w-60"
            placeholder="Enter amount"
            required
          ></input>
          <select className="form-select rounded-pill border-round-xl bg-purple w-30">
            <option>NGN</option>
            <option>USD</option>
          </select>
        </div>
      </div>
      <div className="form-group mb-4">
        <label className="fw-bold mb-2">Caution Fee</label>
        <div className="input-group d-flex flex-row gap-2">
          <input
            type="text"
            defaultValue={cautionFee}
            value={formatNumberWithCommas(cautionFee)}
            min={1}
            onChange={(e) => setCautionFee(e.target.value.replace(/,/g, ""))}
            className="form-control rounded-pill px-4 py-3 bg-purple w-60"
            placeholder="Enter amount"
            required
          ></input>
          <select className="form-select rounded-pill border-round-xl bg-purple w-30">
            <option>NGN</option>
            <option>USD</option>
          </select>
        </div>
      </div>

      <div className="col-12 text-end m-2">
        <button className="btn-el fw-bold mr-3" onClick={onPrev}>
          Go Back
        </button>
        <button className="btn btn-outline btn-dark py-3 px-5" type="submit">
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step6({
  // safetyFeatures,
  // setSafetyFeatures,
  amenities,
  setAmenities,
  onNext,
  onPrev,
}: any) {
  const [allAmenities, setAllAmenities] = useState<Amenities[]>([]);
  const [tempAmenities, setTempAmenities] = useState<Amenities[]>([]);

  const handleOnChange = (e: any, f: Amenities, title: string) => {
    const newState: Amenities = { icon: "", offers: [], title: "" };
    let tempOffers: string[] = [];

    // iterate over the selected amenities and create offers array
    for (let i = 0; i < e.length; i++) {
      tempOffers.push(e[i].value);
    }
    // Update the state offers
    amenities.length > 0 &&
      setAmenities((old: Amenities[]) => {
        const off = old.filter((item) => item.title === f.title);

        return [...old, (off[0].offers = tempOffers)];
      });

    const newObj: Amenities = {
      ...newState,
      offers: tempOffers,
      title,
    };

    updateArray(newObj, setTempAmenities);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    setAmenities(tempAmenities);

    onNext();
  };

  useEffect(() => {
    const abortController = new AbortController();
    getPropertyAmenities(abortController.signal)
      .then((resp: any) => {
        const filteredAmenities = resp.data.filter(
          (amenity: Amenities) => amenity.title !== "Amenities Checklist"
        );
        setAllAmenities(filteredAmenities);
      })
      .catch((err) => {
        ktoast.error(err.errors[0].message);
      });
    if (amenities.length > 0) {
      setTempAmenities(amenities);
    }
  }, []);
  return (
    <form className="needs-validation" onSubmit={handleOnSubmit} noValidate>
      <h4 className="fs-18 fw-bold mb-4">
        Let your customers know the features of your property
      </h4>
      {allAmenities?.map((f: Amenities, i: number) => {
        const { offers, title, id } = f;
        const opt = offers.map((o: any) => {
          return { label: o, value: o };
        });
        const existing =
          amenities && amenities?.map((item: any) => item.offers);

        return (
          <div className="form-group mb-4" key={id}>
            <label className="fw-bold mb-2">{title}</label>
            <Select
              isMulti
              name={title}
              options={opt}
              className="basic-multi-select"
              classNamePrefix="form-select"
              onChange={(e) => handleOnChange(e, f, title)}
            />

            <div className="row">
              {existing &&
                existing[i]?.map((item: string[], index: number) => (
                  <div className="col-6 col-md-3" key={index}>
                    <div className="border border-round-xl p-1 mt-2 text-sm text-center border-label">
                      {item}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      })}

      <div className="col-12 text-end m-2">
        <button className="btn-el fw-bold mr-3" onClick={onPrev}>
          Go Back
        </button>
        <button className="btn btn-outline btn-dark py-3 px-5" type="submit">
          Continue
        </button>
      </div>
    </form>
  );
}

export function Step7({
  state,
  setState,
  apartment,
  setApartment,
  address,
  setAddress,
  city,
  setCity,
  country,
  setCountry,
  onPrev,
  onNext,
  showSuccessModal,
  // loading,
  // setLoading,
  setShowSuccessModal,
  handleCreate,

  // setFlood_meter,
  setNeighbour_story,
  // flood_meter,
  setGeo,
  editID,
}: any) {
  // const [story,setStory]=useState<any>({})
  // const handleStoryChange=()=>{

  // }

  const navigate = useNavigate();

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    // onNext();
    handleCreate();
  };
  // map start
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env["REACT_APP_GOOGLE_API_KEY"] || "",
    libraries: ["places"],
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 6.4297,
    lng: 3.4915,
  });
  // map end
  const [autoComplete, setAutoComplete] = useState<any>(null);
  const [autoCompleteCity, setAutoCompleteCity] = useState<any>(null);
  const [autoCompleteState, setAutoCompleteState] = useState<any>(null);
  const [story, setStory] = useState<any>();
  const [allStory, setAllStory] = useState<any>({});

  let closestPoint: any = null;
  let closestDistance = Infinity;

  const onLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };
  const onLoadCity = (autocomplete: any) => {
    setAutoCompleteCity(autocomplete);
  };
  const onLoadState = (autocomplete: any) => {
    setAutoCompleteState(autocomplete);
  };
  const onStoryLoad = (autocomplete: any) => {
    setStory(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const newLat = autoComplete?.getPlace()?.geometry?.location?.lat();
      const newLng = autoComplete?.getPlace()?.geometry?.location?.lng();
      const newAdd = autoComplete?.getPlace()?.formatted_address;

      setCenter({ lat: newLat, lng: newLng });
      setAddress(newAdd);
      setGeo([newLat, newLng]);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  const onCityChanged = () => {
    if (autoCompleteCity !== null) {
      const locality =
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes("sublocality_level_1")
          )?.long_name ||
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes("neighborhood")
          )?.long_name ||
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) => p.types.includes("locality"))
          ?.long_name ||
        autoCompleteCity
          .getPlace()
          .address_components?.find((p: any) =>
            p.types.includes("administrative_area_level_1")
          )?.long_name;

      setCity(locality);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  const onStateChanged = () => {
    if (autoCompleteState !== null) {
      const state = autoCompleteState
        .getPlace()
        .address_components?.find((p: any) =>
          p.types.includes("locality")
        ).long_name;
      setState(state);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  const onStoryChanged = () => {
    if (story) {
      const result = story.getPlaces();

      const points = result.map((m: any) => {
        return {
          lat: m.geometry.location.lat(),
          lng: m.geometry.location.lng(),
        };
      });

      // Define the array of points

      // Function to calculate the Euclidean distance between two points
      const distance = (p1: any, p2: any) => {
        const dx = p1.lat - p2.lat;
        const dy = p1.lng - p2.lng;
        return Math.sqrt(dx * dx + dy * dy);
      };

      // Initialize variables to keep track of the closest point and distance

      // Loop over each point in the array
      points.forEach((point: any) => {
        // Calculate the distance between the point and the given point
        const dist = distance(point, center);
        // If the distance is smaller than the current closest distance, update the closest point and distance
        if (dist < closestDistance) {
          closestPoint = point;
          closestDistance = dist;
        }
      });

      const place = story.getPlaces()[0].types[0];

      // if (story.getPlaces()[0].types.includes('hospital')) {
      let temp: any = [];
      // if (story.getPlaces()[0].types.includes(place)) {
      //   if (result.length > 1) {
      //     for (let i = 0; i < result.length; i++) {
      //       const {
      //         business_status,
      //         formatted_address,
      //         geometry,
      //         icon,
      //         name,
      //         place_id,
      //         rating,
      //       } = result[i];
      //       temp.push({
      //         business_status,
      //         formatted_address,
      //         geometry,
      //         icon,
      //         name,
      //         place_id,
      //         rating,
      //       });
      //     }
      //   } else {
      //     for (let i = 0; i < result.length; i++) {
      //       const {
      //         business_status,
      //         formatted_address,
      //         geometry,
      //         icon,
      //         name,
      //         place_id,
      //         rating,
      //       } = result[i];
      //       temp.push({
      //         business_status,
      //         formatted_address,
      //         geometry,
      //         icon,
      //         name,
      //         place_id,
      //         rating,
      //       });
      //     }
      //   }
      // }

      const newAllStory: any = {};
      const f = result.filter((d: any) => {
        return (
          d.geometry.location.lat() === closestPoint.lat &&
          d.geometry.location.lng() === closestPoint.lng
        );
      });

      const {
        business_status,
        formatted_address,
        geometry,
        icon,
        name,
        place_id,
        rating,
      } = f[0];
      temp = [
        {
          business_status,
          formatted_address,
          geometry,
          icon,
          name,
          place_id,
          rating,
        },
      ];

      newAllStory[place] = temp;
      setAllStory({ ...allStory, ...newAllStory });
      setNeighbour_story({ ...allStory, ...newAllStory });
    }

    // const k = Object.keys(allStory);
    // const x = k.map((i) => {
    //   return allStory[i];
    // });

    // setDetails();
    // console.log(x);
  };

  const details = Object.entries(allStory);
  return (
    <>
      <form className="needs-validation" onSubmit={handleOnSubmit} noValidate>
        <h4 className="fs-18 fw-bold mb-4">Location Details</h4>

        <div className="row">
          <div
            className="col-12 mb-4"
            style={{ height: "15rem", position: "relative" }}
          >
            {/* <img src={map} alt='' className='w-100 img-fluid' /> */}
            {/* <Gmap coordinate={[4.55207, 8.48268]} height={'15rem'} /> */}
            {isLoaded && (
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: "100%", height: "100%" }}
                // options={{
                //   zoomControl: false,
                //   streetViewControl: false,
                //   mapTypeControl: false,
                //   fullscreenControl: false,
                // }}
                onLoad={(map) => setMap(map)}
              >
                <Marker position={center} />
              </GoogleMap>
            )}
            <div
              className="me-auto bg-white rounded-circle d-flex align-items-center justify-content-center social-icon mb-2 cursor-pointer"
              style={{ position: "absolute", left: "30px", bottom: "15px" }}
            >
              <i
                className="fa fa-location-arrow fs-22 text-primary"
                onClick={() => {
                  map!.panTo(center);
                  map!.setZoom(15);
                }}
              ></i>
            </div>
          </div>

          <div
            className=" p-3 d-flex  gap-2 mb-2 alert alert-warning"
            style={{ background: "#FEFCE8", color: "#854D0E" }}
            role="alert"
          >
            <div className="">
              <i
                className="fa fa-triangle-exclamation "
                style={{ color: "#EFDB11" }}
              ></i>
            </div>
            <div>
              <p className="fw-bold fs-12">Attention</p>
              <p className=" fs-13">
                For address accuracy and consistency please select the google
                suggestion as you type on all fields
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3 mb-lg-5">
            <div className="form-group">
              <label className="fw-bold mb-2">Enter Address</label>
              {isLoaded ? (
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <input
                    type="text"
                    className="form-control rounded-pill px-4 py-3 bg-purple"
                    // className='form-control border-round-xl bg-purple'
                    placeholder="Enter address"
                    autoComplete="new-password"
                    defaultValue={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  ></input>
                </Autocomplete>
              ) : (
                <input
                  type="text"
                  className="form-control border-round-xl bg-purple"
                  placeholder="Enter address"
                  autoComplete="new-password"
                  defaultValue={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  required
                ></input>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className="form-group">
              <label className="fw-bold mb-2">Apartment No</label>

              <input
                type="text"
                className="form-control rounded-pill px-4 py-3 bg-purple"
                placeholder="Enter Apartment No"
                defaultValue={apartment}
                onChange={(e) => setApartment(e.target.value)}
                required
              ></input>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className="form-group">
              <label className="fw-bold mb-2">City</label>
              {isLoaded ? (
                <Autocomplete
                  onLoad={onLoadCity}
                  onPlaceChanged={onCityChanged}
                >
                  <input
                    type="text"
                    className="form-control rounded-pill px-4 py-3 bg-purple"
                    placeholder="Enter City"
                    defaultValue={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  ></input>
                </Autocomplete>
              ) : (
                <input
                  type="text"
                  className="form-control rounded-pill px-4 py-3 bg-purple"
                  placeholder="Enter City"
                  defaultValue={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                ></input>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className="form-group">
              <label className="fw-bold mb-2">State / Province</label>
              {isLoaded ? (
                <Autocomplete
                  onLoad={onLoadState}
                  onPlaceChanged={onStateChanged}
                >
                  <input
                    type="text"
                    className="form-control rounded-pill px-4 py-3 bg-purple"
                    placeholder="Enter State"
                    defaultValue={state}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                    required
                  ></input>
                </Autocomplete>
              ) : (
                <input
                  type="text"
                  className="form-control rounded-pill px-4 py-3 bg-purple"
                  placeholder="Enter State"
                  defaultValue={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                ></input>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <div className="form-group">
              <label className="fw-bold mb-2">Country</label>

              <select
                className="form-select rounded-pill px-4 py-3 bg-purple"
                defaultValue={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                <option value="">Select Country</option>
                <option value="Nigeria">Nigeria</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            {/* <div className='form-group'>
              <label className='fw-bold mb-2'>Flood Risk</label>

              <select
                className='form-select rounded-pill px-4 py-3 bg-purple'
                defaultValue={flood_meter}
                onChange={(e) => {
                  setFlood_meter(e.target.value);
                }}
              >
                <option value=''>Select Flood Risk</option>
                <option value='low'>Low</option>
                <option value='medium'>Medium</option>
                <option value='high'>High</option>
              </select>
            </div> */}
          </div>
        </div>
        <div className="row mt-3">
          {isLoaded && (
            <div className="col-12  col-lg-6 ">
              <div className="form-group">
                <label className="fw-bold mb-2">Neighbourhood</label>
                <p className="fs-14 text-secondary">
                  {` Help people see the landmarks,point of interest,popular places
                  (Hospitals, supermarkets, busstops, night clubs etc..)
                  around this property. Type in the name of a popular place near
                  the street this property is located.eg 'hospitals near kofo
                  abayomi street' and select an option that matches your
                  search.Once you see a list with heading appear on the screen, the list you see has been marked and selected. You can go ahead and search for another place.`}
                </p>
                <StandaloneSearchBox
                  onLoad={onStoryLoad}
                  onPlacesChanged={onStoryChanged}
                >
                  <input
                    type="text"
                    placeholder="Eg. lounge near kofo abayomi street"
                    className="form-control rounded-pill px-4 py-3 bg-purple"
                  />
                </StandaloneSearchBox>
              </div>
            </div>
          )}
          {details &&
            details?.map((item: any, index: number) => {
              return (
                <div className="col-6 col-lg-2 mt-3" key={index}>
                  <div className=" fw-bold ">
                    {item[0].replace(/_/g, " ").toUpperCase().slice(0, 10)}
                  </div>
                  <div className=" light-text">
                    {item[1]?.map((i: any) => {
                      return (
                        <div key={i.place_id}>
                          <span>{i.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="col-12 text-end m-2">
          <button className="btn-el fw-bold mr-3" onClick={onPrev}>
            Go Back
          </button>
          <button className="btn btn-outline btn-dark py-3 px-5" type="submit">
            Continue
          </button>
        </div>
      </form>

      <Modal
        show={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        dismissible={false}
      >
        <div className="col px-4">
          <h6 className="fw-bold fs-20">
            {editID
              ? "Property Updated Successfully and will be Unpublished. Krent will review and Publish"
              : "Property Created Successfully but not Published."}
          </h6>

          <p className="light-text">
            {editID
              ? "If you are keeping the old pictures you dont have to reupload them else kindly proceed to upload new ones"
              : "Kindly proceed to add images to your listing."}
          </p>

          <div className="row">
            <div className="col-6">
              <PrimaryButton
                text="Continue"
                type="primary"
                click={() => {
                  setShowSuccessModal(false);
                  onNext();
                }}
              />
            </div>
            <div className="col-6">
              <PrimaryButton
                text="Done"
                type="primary"
                click={() => {
                  navigate({
                    pathname: "/listings/",
                  });
                }}
              />
            </div>
            {/* <div className='col-6'>
              <PrimaryButton
                text='Do this later'
                type='outline'
                click={() => {
                 
                }}
              />
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export function Step8({
  videoUrl,
  setVideoUrl,
  tourUrl,
  setTourUrl,
  tourIntrest,
  setTourIntrest,
  // onPrev,
  propertyId,
  loading,
  setLoading,
  affiliation,
  virtualTourDate,
  setVirtualTourDate,
}: // propertyCreated,
any) {
  const [form, setForm]: any = useState({});
  const [coverImage1, setCoverImage1]: any = useState([]);
  const [otherImages1, setOtherImages1]: any = useState([]);
  const [floorPlans1, setFloorPlans1]: any = useState([]);

  const [coverUrlPath, setCoverUrlPath]: any = useState();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showTour, setShowTour] = useState<boolean>(false);
  const [modalType, setModalType] = useState<boolean>(false);

  const location: any = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editID = searchParams.get("id");

  const datePickerMinDate = new Date();

  const navigate = useNavigate();

  const handleChange = (e: any) => {
    const value = e.target.files;
    const name = e.target.name;

    if (name === "coverImage") {
      setCoverImage1(value);
      const [file] = value;
      setCoverUrlPath(URL.createObjectURL(file));
    }

    if (name === "floorPlans") {
      const newPath = [];

      for (let i = 0; i < value.length; i++) {
        newPath.push({ id: `${uuidv4()}`, pic: value[i] });
      }

      setFloorPlans1([...floorPlans1, ...newPath]);
    }

    if (name === "otherImages") {
      const newPath = [];

      // Ensure the total number of images (existing + new) does not exceed 10
      const totalImages = otherImages1.length + value.length;
      if (totalImages > 10) {
        const remainingSlots = 10 - otherImages1.length;
        for (let i = 0; i < remainingSlots; i++) {
          newPath.push({ id: `${uuidv4()}`, pic: value[i] });
        }
      } else {
        for (let i = 0; i < value.length; i++) {
          newPath.push({ id: `${uuidv4()}`, pic: value[i] });
        }
      }

      setOtherImages1([...otherImages1, ...newPath]);
    }

    setForm({ ...form, [name]: value });
  };

  const handleDelete = (id: string) => {
    const newData = otherImages1.filter((item: any) => item.id !== id);
    const newFloorData = floorPlans1.filter((item: any) => item.id !== id);
    setOtherImages1(newData);
    setFloorPlans1(newFloorData);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };

  const showM = (id: any) => {
    const formData = new FormData();
    setLoading(true);

    if (Array.isArray(otherImages1) && otherImages1.length > 0) {
      otherImages1.forEach((image) => {
        if (image && image.pic !== undefined) {
          formData.append("otherImages", image.pic);
        }
      });
    }

    for (let i = 0; i < coverImage1?.length; i++) {
      formData.append("coverImage", coverImage1[i]);
    }
    if (Array.isArray(floorPlans1) && floorPlans1.length > 0) {
      floorPlans1.forEach((plan) => {
        if (plan && plan.pic !== undefined) {
          formData.append("floorPlans", plan.pic);
        }
      });
    }

    addProperImages(id, formData)
      .then(() => {
        navigate(
          {
            pathname: "/listings/",
          },
          {
            state: {
              showToast: true,
              toastMessage: "Listing uploaded successfully",
            },
            replace: true,
          }
        );
      })
      .catch((err) => {
        setShowModal(false);
        setLoading(false);

        ktoast.error(err?.errors[0]?.message);
        // ktoast.error(
        //   err.errors[0].message
        //     ? err.errors[0].message
        //     : 'Unable to upload images'
        // );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const deleteProp = (id: any) => {
  //   deleteProperty(id)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const updateProp = (id: any, payload: {}) => {
    updateProperty(id, payload)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (editID) {
      let abortController = new AbortController();
      getSingleListingProperty(editID, abortController.signal)
        .then((res: any) => {
          // setEditData(res.data);

          setCoverUrlPath(res?.data?.coverImage);
          setOtherImages1(res?.data?.otherImages);
          console.log(res.data.otherImages);
        })
        .catch((err) => {
          console.log(err.errors[0].message);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [editID]);

  return (
    <>
      <form className="needs-validation" onSubmit={handleSubmit} noValidate>
        <div className="mb-4">
          <h4 className="fs-18 fw-bold">Let people see your space</h4>
          <p className="light-text d-none d-md-flex">
            Upload Images of this space (1 Cover Image, upto 10 Other Images,
            upto 3 Floor Plans)
          </p>
        </div>

        <div className="row">
          {/* {Object.values(selectedFiles).map((_, k) => {
          return <ImageUploadCard key={k} />;
        })} */}
          {/* coverImage */}
          <div className={`col-12 ${coverUrlPath ? "mb-0" : "mb-5"}`}>
            <input
              type="file"
              hidden
              accept="image/*"
              name="coverImage"
              id="coverImage"
              onChange={handleChange}
            />
            <label
              className="d-flex flex-row align-items-center justify-content-center border border-round p-2 cursor-pointer"
              htmlFor="coverImage"
            >
              <i className="iconly-Plus icbo text-secondary fs-30 mx-2"></i>
              <p className="m-0 fw-600">Tap To Add a Cover Image</p>
            </label>
            {coverUrlPath && (
              <img
                src={coverUrlPath}
                alt="house"
                style={{ height: "4rem", width: "4rem", margin: "1rem" }}
              />
            )}
          </div>
          {/* otherImages */}
          <div
            className={`col-12 ${otherImages1?.length !== 0 ? "mb-0" : "mb-5"}`}
          >
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              name="otherImages"
              id="otherImages"
              onChange={handleChange}
            />
            <label
              className="d-flex flex-row align-items-center justify-content-center border border-round p-2 cursor-pointer"
              htmlFor="otherImages"
            >
              <i className="iconly-Plus icbo text-secondary fs-30 mx-2"></i>
              <p className="m-0 fw-600">Tap To Add Other Images</p>
            </label>
            {otherImages1.length < 5 ? (
              <span className="text-danger d-block fs-12">
                Minimum of 5 pictures are required to continue
              </span>
            ) : (
              <span className="text-danger d-block fs-12">
                The maximum number of images you can upload is 10
              </span>
            )}
            {otherImages1 &&
              otherImages1?.map((item: any) => {
                let pic;

                if (item.pic) {
                  pic = URL.createObjectURL(item.pic);
                } else {
                  pic = item;
                }
                return (
                  <div
                    className="position-relative uploadPic"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      margin: "1rem",
                    }}
                    key={item.id}
                  >
                    <img src={pic} alt="house" className="h-100 w-100" />
                    {/* {!editID && ( */}
                    <div className="delete-container position-absolute  ">
                      <span
                        className="fa fa-times text-danger  cursor-pointer deletePic"
                        onClick={() => handleDelete(item.id)}
                      ></span>
                    </div>
                    {/* )} */}
                  </div>
                );
              })}
          </div>

          {/* floorPlans */}
          {/* <div
            className={`col-12 ${floorPlans1.length !== 0 ? 'mb-5' : 'mb-0'}`}
          >
            <input
              type='file'
              accept='image/*'
              hidden
              multiple
              name='floorPlans'
              id='floorPlans'
              onChange={handleChange}
            />
            <label
              className='d-flex flex-row align-items-center justify-content-center border border-round p-2 cursor-pointer'
              htmlFor='floorPlans'
            >
              <i className='iconly-Plus icbo text-secondary fs-30 mx-2'></i>
              <p className='m-0 fw-600'>Tap To Add Floor Plans</p>
            </label>
            {floorPlans1 &&
              floorPlans1?.map((item: any) => {
                return (
                  <div
                    className='position-relative uploadPic'
                    style={{
                      height: '4rem',
                      width: '4rem',
                      margin: '1rem',
                    }}
                    key={item.id}
                  >
                    <img
                      src={URL.createObjectURL(item.pic)}
                      alt='house'
                      className='h-100 w-100'
                    />
                    <div className='delete-container position-absolute  '>
                      <span
                        className='fa fa-times text-danger  cursor-pointer deletePic'
                        onClick={() => handleDelete(item.id)}
                      ></span>
                    </div>
                  </div>
                );
              })}
          </div> */}
        </div>

        <div className="col-12 text-end m-2">
          {/* <button
            className='btn-el fw-bold mr-3 '
            
            onClick={() => {
              onPrev();
              deleteProp(propertyId);
            }}
            disabled={propertyCreated }
          >
            Go Back
          </button> */}
          <button
            className="btn btn-outline btn-dark py-3 px-5"
            type="submit"
            disabled={otherImages1?.length < 5}
            // onClick={()=>{console.log(otherImages1);
            // }}
          >
            Continue
          </button>
        </div>
      </form>
      {showModal && (
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          <div className="col px-4">
            <h6 className="fw-bold fs-20">
              Are you ready to publish this property?
            </h6>
            <p className="light-text">
              Once you publish this property, Krent will review your listing and
              your listing would go live once it is approved.
            </p>
            <p className="light-text">
              If you have a video tour of this property please provide the link
              in the video tour input field below
            </p>
            <p className="light-text">
              If you have a virtual tour of this property please provide the
              link in the virtual tour input field below. If you don't and would
              like Krent to take a virtual tour of this property, please check
              the box below.
            </p>
            {affiliation !== "Property owner" && (
              <p className=" text-success">
                {" "}
                Since you've listed this property as {affiliation}, we'll
                request for the property owner's account details to transfer the
                due amount to the landlord. Once the property is paid for, we'll
                also remit your commission to your account.{" "}
              </p>
            )}
            {!editID && (
              <div className="row g-0 align-items-center mb-3 mt-5 ">
                <div className="col-12 form-check">
                  <input
                    className="form-check-input cursor-pointer"
                    name="tour intrest"
                    value={tourIntrest}
                    id="tour"
                    type="checkbox"
                    onChange={() => {
                      setTourIntrest((tourIntrest: boolean) => !tourIntrest);
                    }}
                  />
                  <label
                    htmlFor="tour"
                    className="form-check-label text-secondary fs-14 m-0"
                  >
                    I would love Krent to take a virtual tour of this Listing{" "}
                  </label>
                  <PropertyVideo
                    url={"https://www.youtube.com/shorts/Qj68HWHQ10c"}
                    virtualTour2
                  />
                  {/* <span
                  className='text-primary cursor-pointer  d-block'
                  // onClick={() => {
                  //   setModalType(true);
                  //   setShowTour(true);
                  //   setShowModal(false);
                  // }}
                >
                  Click to see a sample{' '}
                 
                </span> */}

                  {tourIntrest && (
                    <div className="form-group py-3">
                      <span>
                        Please pick a date for the virtual tour production
                      </span>
                      <div className="input-group">
                        <span className="input-group-text input-group-text-0">
                          <i className={`iconly-Calendar icli fs-4`}></i>
                        </span>

                        <div className="form-control form-control-0   border-start-0">
                          <DatePicker
                            selected={virtualTourDate}
                            onChange={(date: any) => setVirtualTourDate(date)}
                            placeholderText="dd-mm-yyyy"
                            minDate={datePickerMinDate}
                            className="form-control form-control-0  border-0"
                            showYearDropdown
                            showMonthDropdown
                            showTimeSelect
                            dateFormat="dd-MM-yyyy"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="row my-5">
              <div className="col-12 col-md-6 mb-4 mb-md-0">
                <div className="form-group">
                  <label className="fw-bold mb-2">Video Tour (Optional)</label>
                  <input
                    type="text"
                    defaultValue={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    className="form-control rounded-pill px-4 py-3 bg-purple"
                    placeholder="Enter URL"
                  ></input>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="fw-bold mb-2">
                    Virtual Tour (Optional)
                  </label>
                  <input
                    type="text"
                    defaultValue={tourUrl}
                    onChange={(e) => {
                      e.preventDefault();
                      setTourUrl(e.target.value);
                    }}
                    className="form-control rounded-pill px-4 py-3 bg-purple"
                    placeholder="Enter URL"
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <PrimaryButton
                  text="Publish"
                  type="primary"
                  loading={loading}
                  click={() => {
                    showM(propertyId || editID);
                    if (tourUrl || videoUrl || tourIntrest) {
                      updateProp(propertyId, {
                        virtual_tour_url: tourUrl,
                        video_url: videoUrl,
                        virtual_tour: tourIntrest,
                        virtual_tour_date_request: virtualTourDate,
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <PrimaryButton
                  text="Do this later"
                  type="primary"
                  click={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modalType && (
        <Modal
          show={showTour}
          onClose={() => {
            setShowTour(false);
            setShowModal(true);

            setTimeout(() => {
              setModalType(false);
            }, 500);
          }}
          size={600}
          position="top"
        >
          <div className="row px-4 py-1">
            <div className="d-flex flex-column mb-5">
              <h3 className="fs-5 fw-bold my-4">Virtual Tour</h3>

              <div className="embed-responsive embed-responsive-16by9">
                {/* <iframe
                  width='100%'
                  height='380'
                  src='https://www.youtube.com/watch?v=vypd9r0jJW8'
                  title='Virtual Tour'
                  frameBorder={0}
                  // allow='vr'
                  allowFullScreen
                ></iframe> */}
                {/* <iframe
                  key='vypd9r0jJW8'
                  src='https://www.youtube.com/watch?v=vypd9r0jJW8'
                  // ref={iframeRef}
                  width='100%'
                  height='380'
                  title='virtual tour'
                  style={{ border: 0 }}
                ></iframe> */}
                <PropertyVideo
                  url={"https://www.youtube.com/shorts/Qj68HWHQ10c"}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
